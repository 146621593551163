import axios from 'axios';
import { Notification, MessageBox, Message, Loading } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import errorCode from '@/utils/errorCode';
import { tansParams, blobValidate } from '@/utils/ruoyi';
import { saveAs } from 'file-saver';
import disable from './json/disable.json';
import sex from './json/sex.json';
import hide from './json/hide.json';
import deletes from './json/delete.json';
import router from '@/router';
let downloadLoadingInstance;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    const url = res.config.url;
    if (url.indexOf('sys_normal_disable') > 0) {
      return disable;
    }
    if (url.indexOf('sys_user_sex') > 0) {
      return sex;
    }
    if (url.indexOf('sys_show_hide') > 0) {
      return hide;
    }
    if (url.indexOf('sys_normal_delete') > 0) {
      return deletes;
    }
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default'];
    // 二进制数据则直接返回
    if (
      res.request.responseType === 'blob' ||
      res.request.responseType === 'arraybuffer'
    ) {
      return res.data;
    }
    if (code === 401) {
      MessageBox.confirm(
        '登录状态已过期，您可以继续留在该页面，或者重新登录',
        '系统提示',
        {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning',
          showClose: false,
        }
      )
        .then(() => {
          let flag = false; //是否是所属管理台页面
          let pathList = [
            'login',
            'user',
            'index',
            'system',
            'news',
            'activity',
            'topic',
            'trend',
            'committee',
            'audit',
            'about',
            'banner',
          ];
          let hash = window.location.hash.split('/')[1];
          try {
            pathList.forEach((element) => {
              console.log(element === hash);
              if (element === hash) {
                flag = true;
                throw new Error('End Loop');
              }
            });
          } catch (error) {}
          store.dispatch('LogOut').then(() => {
            if (flag) {
              router.push({ path: '/login' });
            } else {
              router.push({ path: '/cmsLogin' });
            }
          });
        })
        .catch(() => {});
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    } else if (code === 500) {
      Message({
        message: msg,
        type: 'error',
      });
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      Notification.error({
        offset: 100,
        title: msg,
      });
      return Promise.reject('error');
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log('err' + error);
    let { message } = error;
    if (message == 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

// 通用下载方法
export function download(url, params, filename) {
  downloadLoadingInstance = Loading.service({
    text: '正在下载数据，请稍候',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  return service
    .post(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
    })
    .then(async (data) => {
      const isLogin = await blobValidate(data);
      if (isLogin) {
        const blob = new Blob([data]);
        saveAs(blob, filename);
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg =
          errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
        Message.error(errMsg);
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error('下载文件出现错误，请联系管理员！');
      downloadLoadingInstance.close();
    });
}
export function downloadExcel(url, name, params) {
  return service
    .post(url, params, {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    })
    .then((res) => {
      console.log(res);
      const link = document.createElement('a');
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', name + '.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      this.$Notice.error({
        title: '错误',
        desc: '网络连接错误',
      });
      console.log(error);
    });
}

export default service;
