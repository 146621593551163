import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: (resolve) => require(['@/views/redirect'], resolve)
    }]
  },
  {
    path: '/',
    component: (resolve) => require(['@/views/cms/index'], resolve),
    hidden: true,
    redirect: '/main/cmsIndex',
    children: [
      {
        path: 'main',
        component: (resolve) => require(['@/views/cms/main'], resolve),
        hidden: true,
        children: [
          {
            path: 'cmsIndex',
            component: (resolve) => require(['@/views/cms/components/cmsIndex'], resolve),
            name: 'cmsIndex',
            meta: {
              title: '首页',
            }
          },
          {
            path: 'innvoate',
            component: (resolve) => require(['@/views/cms/components/innvoate/index'], resolve),
            name: 'innvoate',
            meta: {
              title: '创新试点',
            }
          },
          {
            path: 'innDetail',
            component: (resolve) => require(['@/views/cms/components/innvoate/innDetail'], resolve),
            name: 'innDetail',
            meta: {
              title: '工作详情',
            }
          },
          {
            path: 'newsTrends',
            component: (resolve) => require(['@/views/cms/components/newsTrends/index'], resolve),
            name: 'newsTrends',
            meta: {
              title: '新闻动态',
            }
          },
          {
            path: 'newDetail',
            component: (resolve) => require(['@/views/cms/components/newsTrends/newDetail'], resolve),
            name: 'newDetail',
            meta: {
              title: '新闻详情',
            }
          },
          {
            path: 'community',
            component: (resolve) => require(['@/views/cms/components/cmsIndex'], resolve),
            name: 'community',
            meta: {
              title: '创新社区',
            }
          },
          {
            path: 'aboutme',
            component: (resolve) => require(['@/views/cms/components/aboutme'], resolve),
            name: 'aboutme',
            meta: {
              title: '关于我们',
            }
          },
          {
            path: 'research',
            component: (resolve) => require(['@/views/cms/components/research/index'], resolve),
            name: 'research',
            meta: {
              title: '课题研究',
            }
          },
          {
            path: 'resDetail',
            component: (resolve) => require(['@/views/cms/components/research/resDetail'], resolve),
            name: 'resDetail',
            meta: {
              title: '课题研究详情',
            }
          },
          {
            path: 'about',
            component: (resolve) => require(['@/views/cms/components/user/index'], resolve),
            name: 'user',
            redirect: '/main/about/message',
            children:[
              {
                path: 'message',
                component: (resolve) => require(['@/views/cms/components/message/index'], resolve),
                name: 'message',
                meta: {
                  title: '基本信息',
                  requireAuth: true
                }
              },
              {
                path: 'authentication',
                component: (resolve) => require(['@/views/cms/components/authentication/index'], resolve),
                name: 'authentication',
                meta: {
                  title: '账号认证',
                }
              },
              {
                path: 'notification',
                component: (resolve) => require(['@/views/cms/components/notification/index'], resolve),
                name: 'notification',
                meta: {
                  title: '消息通知',
                }
              },
              {
                path: 'subject',
                component: (resolve) => require(['@/views/cms/components/subject/index'], resolve),
                name: 'subject',
                meta: {
                  title: '我的课题',
                }
              }]
          },
          {
            path: 'activity',
            component: (resolve) => require(['@/views/cms/components/activity/index'], resolve),
            name: 'activity',
            meta: {
              title: '活动',
            }
          },
          {
            path: 'sign',
            component: (resolve) => require(['@/views/cms/components/sign/index'], resolve),
            name: 'sign',
            meta: {
              title: '活动报名',
            }
          },
          {
            path: 'actDetail',
            component: (resolve) => require(['@/views/cms/components/activity/actDetail'], resolve),
            name: 'actDetail',
            meta: {
              title: '活动详情',
            }
          }
        ]
      }
    ]
  },
  {
    path: '/cmsLogin',
    component: (resolve) => require(['@/views/cms/cmslogin'], resolve),
    hidden: true,
  },
  {
    path: '/cmsRegister',
    component: (resolve) => require(['@/views/cms/cmsRegister'], resolve),
    hidden: true
  },
  {
    path: '/admin',
    redirect: '/login',
    hidden: true
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: '/cms',
    children: [{
      path: 'index',
      component: (resolve) => require(['@/views/index_v2'], resolve),
      name: 'Index',
      meta: {
        title: '首页',
        icon: 'dashboard',
        affix: true
      }
    }]
    },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
      path: 'profile',
      component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
      name: 'Profile',
      meta: {
        title: '个人中心',
        icon: 'user'
      }
    }]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    children: [{
      path: 'role/:userId(\\d+)',
      component: (resolve) => require(['@/views/system/user/authRole'], resolve),
      name: 'AuthRole',
      meta: {
        title: '分配角色',
        activeMenu: '/system/user'
      }
    }]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    children: [{
      path: 'user/:roleId(\\d+)',
      component: (resolve) => require(['@/views/system/role/authUser'], resolve),
      name: 'AuthUser',
      meta: {
        title: '分配用户',
        activeMenu: '/system/role'
      }
    }]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    children: [{
      path: 'index/:dictId(\\d+)',
      component: (resolve) => require(['@/views/system/dict/data'], resolve),
      name: 'Data',
      meta: {
        title: '字典数据',
        activeMenu: '/system/dict'
      }
    }]
  },
  {
    path: '/active/index',
    component: Layout,
    hidden: true
  }
]

export default new Router({
  mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
