// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.ttf?t=1693372046865");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"iconfont\"; /* Project id  */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n.iconfont {\n  font-family: \"iconfont\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-close:before {\n  content: \"\\e646\";\n}\n\n.icon-shaixuan:before {\n  content: \"\\e74a\";\n}\n\n.icon-wangzhi:before {\n  content: \"\\e62b\";\n}\n\n.icon-eyeslash-fill:before {\n  content: \"\\e7aa\";\n}\n\n.icon-eye:before {\n  content: \"\\e7ed\";\n}\n\n", ""]);
// Exports
module.exports = exports;
