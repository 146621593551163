//搜索是否显示
export const SET_SEARCH = 'SET_SEARCH'
export const GET_SEARCH = 'GET_SEARCH'


export default {
  state: {
    searchShow: false
  },
  mutations: {
    [SET_SEARCH](state, searchShow) {
      state.searchShow = searchShow
    },
  },
  getters: {
    [GET_SEARCH](state) {
      return state.searchShow
    }
  }
}
