import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/financial_tech/system/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册用户
export function register(data) {
  return request({
    url: '/financial_tech/system/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/financial_tech/system/getInfo',
    method: 'get'
  })
}
// 发送短信验证码
export function getCodes(data) {
  return request({
    url: '/financial_tech/system/send',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/financial_tech/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/financial_tech/system/getCaptchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 忘记密码
export function forgetPwd(data) {
  return request({
    url: '/financial_tech/system/forgetPwd',
    method: 'post',
    data: data
  })
}
