
export const SET_ROUTE = 'SET_ROUTE'
export const GET_ROUTE = 'GET_ROUTE'

export default {
  state: {
    lastRoute: '',
  },
  mutations: {
    [SET_ROUTE](state, lastRoute) {
      state.lastRoute = lastRoute
    }
  },
  getters: {
    [GET_ROUTE](state) {
      return state.lastRoute
    }
  }
}
