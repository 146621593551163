import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';

NProgress.configure({ showSpinner: false });
// 白名单无需登录
const whiteList = [
  '/',
  '/login',
  '/cms',
  '/cmsLogin',
  '/cmsRegister',
  '/main/cmsIndex',
  '/main/innvoate',
  '/main/news',
  '/main/community',
  '/main/research',
  '/main/about',
  '/main/activity',
  '/main/actDetail',
  '/main/newDetail',
  '/main/resDetail',
  '/main/sign',
  '/main/innvoate',
  '/main/innDetail',
  '/main/aboutme',
  '/main/newsTrends'
];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title);
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch('GetInfo')
          .then(() => {
            store.dispatch('GenerateRoutes').then((accessRoutes) => {
              // 根据roles权限生成可访问的路由表
              router.addRoutes(accessRoutes); // 动态添加可访问路由表
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
            });
          })
          .catch((err) => {
            store.dispatch('LogOut').then(() => {
              Message.error(err);
              next({ path: '/' });
            });
          });
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      let pathName = to.path.toString().split('/')[1];
      let pathList = [
        'login',
        'user',
        'index',
        'system',
        'news',
        'activity',
        'topic',
        'trend',
        'committee',
        'audit',
        'about',
        'banner',
      ];
      let flag = false; //是否是所属管理台页面

      try {
        pathList.forEach((element) => {
          console.log(element.indexOf(pathName));
          if (element.indexOf(pathName) === 0) {
            flag = true;
            throw new Error('End Loop');
          }
        });
      } catch (error) {
      }
      store.commit('SET_ROUTE', {
        route: to.path,
        query: { arguments: false },
      });
      if (flag) {
        next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
      } else {
        next(`/cmsLogin?redirect=${to.fullPath}`); // 否则全部重定向到登录页
      }
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
